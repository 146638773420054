import SVGInjector from 'svg-injector'
import SmoothScroll from 'smooth-scroll'
import Glide from '@glidejs/glide'
import GLightbox from 'glightbox'
import Macy from 'macy'

//svg injection
const mySVGsToInject = document.querySelectorAll('svg[data-src]');
SVGInjector(mySVGsToInject)

window.onload = new function(){
	new SmoothScroll('a[href*="#"]', {
		speed: 1000
	})

	// hamburger menu
	const header = document.getElementById('header')
	const hamburger = document.getElementById('hamburger')
	if(hamburger && header){
		hamburger.addEventListener('click', function(){
			header.classList.toggle('header--mobile-active')
		})
	}

	//herobannerslider
	const herobannerslider = document.getElementsByClassName('hero-banner__slider')
	if(herobannerslider.length > 0){
		var slides = document.querySelectorAll('.hero-banner__slider .glide__slide')
		if(slides.length > 1){
			new Glide(herobannerslider[0], {
				type: 'carousel',
				animationDuration: 800,
				animationTimingFunc: 'ease-in-out',
				autoplay: 8000,
			}).mount()
		}else{
			document.querySelector('.hero-banner__slider .glide__arrows').style.display = 'none';
		}
	}
	
	//homegalleryslider
	const homegalleryslider = document.getElementsByClassName('home-gallery__slider')
	if(homegalleryslider.length > 0){
		new Glide(homegalleryslider[0], {
			type: 'carousel',
			perView: 4,
			animationDuration: 800,
			animationTimingFunc: 'ease-in-out',
			autoplay: 8000,
		}).mount()
		
	}

	//faq
	const accordionBtns = document.getElementsByClassName('faq-item__bar')
	for(let i = 0; i < accordionBtns.length; i++){
		accordionBtns[i].addEventListener('click', function(){
			this.classList.toggle("is-open");

			let content = this.nextElementSibling;

			if (content.style.maxHeight) {
				content.style.maxHeight = null;
			} else {
				content.style.maxHeight = content.scrollHeight + "px";
			}
		})
	}

	//masonry
	var masonry = document.getElementById('macy-container')
	if(masonry){
		var macy = Macy({
			container: '#macy-container',
			trueOrder: false,
			waitForImages: false,
			margin: 24,
			columns: 3,
			breakAt: {
				992: 2,
				576: 1
			}
		});
	}

	//lightbox
	const lightbox = GLightbox({
		selector: '.lightbox',
		touchNavigation: true,
		type: 'image',
		loop: true,
	});

	var lightboxtriggers = document.getElementsByClassName('lightbox-trigger')
	for(let i = 0; i < lightboxtriggers.length; i++){
		lightboxtriggers[i].addEventListener('click', function(e){
			lightbox.open()
		})
	}
}

